<template>
  <div>
    <CRow class="justify-content-between mb-3">
      <CCol sm="auto"
        ><div class="ui-type-display-lg">
          {{ $t("user.profile.mail.title") }}
        </div>
      </CCol>
      <CCol sm="auto">
        <CButton color="primary" @click="onAddEmail()">
          {{ $t("user.profile.mail.addbtn") }}
        </CButton>
      </CCol>
    </CRow>

    <CCard>
      <CCardBody>
        <CAlert v-if="error.hasGeneralErrors()" color="danger" class="mb-3">
          {{ error.general().join(" ") }}
        </CAlert>

        <ejs-grid
          :dataSource="dm"
          :allowPaging="false"
          :allowSorting="true"
          ref="grid"
        >
          <e-columns>
            <e-column
              :headerText="$t('models.email.email')"
              field="email"
            ></e-column>
            <e-column
              :headerText="$t('models.email.verified')"
              field="verified"
              :template="verifiedTemplate"
            ></e-column>
            <e-column
              :headerText="$t('models.email.primary')"
              field="primary"
              :template="primaryTemplate"
            ></e-column>
            <e-column headerText="" :template="actionsTemplate"></e-column>
          </e-columns>
        </ejs-grid>
      </CCardBody>
    </CCard>

    <CModal :show="modal.show" :closeOnBackdrop="false" :centered="true">
      <template #header>
        <h5 class="modal-title">{{ $t("user.profile.mail.modal.title") }}</h5>
      </template>

      <CAlert v-if="modal.error.hasGeneralErrors()" color="danger" class="mb-3">
        {{ modal.error.general().join(" ") }}
      </CAlert>

      <form action="" @submit.prevent="addEmail">
        <CInput
          v-model="modal.form.email"
          :label="this.$t('models.email.email')"
          type="email"
          :isValid="modal.error.isValidField('email')"
          :invalidFeedback="modal.error.fieldError('email')"
          required
        />
      </form>

      <template #footer>
        <CButton
          color="primary"
          variant="outline"
          @click.prevent="closeModal"
          >{{ $t("common.editor.cancel") }}</CButton
        >
        <vue-ladda
          :loading="modal.loading"
          data-style="zoom-in"
          button-class="btn btn-primary px-4"
          @click.prevent="addEmail"
          >{{ $t("common.editor.confirm") }}</vue-ladda
        >
      </template>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import { Page, Sort } from "@syncfusion/ej2-vue-grids";
import { GetDataManagerNew } from "../../../ds";
import EventBus from "../../../helpers/EventBus";
import errorResponse from "../../../helpers/error";

export default Vue.extend({
  name: "Email",

  data() {
    const dm = GetDataManagerNew("auth_email");

    return {
      dm: dm,
      error: errorResponse(),
      modal: {
        show: false,
        loading: false,
        error: errorResponse(),
        form: {
          email: "",
        },
      },

      verifiedTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div class="d-flex justify-content-left">
                <div class="text-success" v-if="isTrue()">
                  <font-awesome-icon icon="check-circle" />
                </div>
                <div class="text-danger" v-else>
                  <font-awesome-icon icon="times-circle" />
                </div>
              </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            methods: {
              isTrue() {
                return this.data["verified"] === true;
              },
            },
          }),
        };
      },

      primaryTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div class="d-flex justify-content-left">
                <div class="text-success" v-if="isTrue()">
                  <font-awesome-icon icon="check-circle" />
                </div>
                <div class="text-danger" v-else>
                  <font-awesome-icon icon="times-circle" />
                </div>
              </div>`,
            data: function () {
              return {
                data: {},
              };
            },
            methods: {
              isTrue() {
                return this.data["primary"] === true;
              },
            },
          }),
        };
      },

      actionsTemplate: function () {
        return {
          template: Vue.component("columnTemplate", {
            template: `<div class="d-flex justify-content-end">
                <dropdown-menu :items="actions" :select="onActionSelect">
                  <font-awesome-icon icon="ellipsis-h"
                /></dropdown-menu>
              </div>`,
            data() {
              const EMAIL_ACTIONS = [
                {
                  id: "MARK_AS_PRIMARY",
                  text: this.$t("user.profile.mail.actions.primary"),
                },
                {
                  id: "DELETE",
                  text: this.$t("user.profile.mail.actions.delete"),
                },
              ];
              return {
                EMAIL_ACTIONS: EMAIL_ACTIONS,
              };
            },
            computed: {
              actions() {
                const isPrimary = this.data.primary === true;
                return this.EMAIL_ACTIONS.filter((a) => {
                  return a.id === "DELETE" && isPrimary ? false : true;
                });
              },
            },
            methods: {
              onActionSelect: function (args) {
                const { id } = args.item;

                if (id === "DELETE") {
                  if (
                    confirm(
                      this.$t("user.profile.mail.actions.delete_confirm", [
                        this.data.email,
                      ])
                    ) == true
                  ) {
                    EventBus.$emit("email:remove", this.data);
                  }
                }

                if (id === "MARK_AS_PRIMARY") {
                  EventBus.$emit("email:mark_as_primary", this.data);
                }
              },
            },
          }),
        };
      },
    };
  },
  provide: {
    grid: [Page, Sort],
  },

  mounted() {
    EventBus.$on("email:remove", this.onRemove);
    EventBus.$on("email:mark_as_primary", this.onMarkAsPrimary);
  },

  beforeDestroy() {
    EventBus.$off("email:remove", this.onRemove);
    EventBus.$off("email:mark_as_primary", this.onMarkAsPrimary);
  },

  methods: {
    onAddEmail() {
      this.modal.error.reset();
      this.modal.form.email = "";
      this.modal.show = true;
    },

    closeModal() {
      this.modal.show = false;
    },

    addEmail() {
      const requestParams = {
        email: this.modal.form.email,
      };
      this.modal.error.reset();
      this.dm
        .insert(requestParams)
        .then((response) => {
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: "this.$t('user.profile.mail.primary_success')",
            color: "success",
            autohide: true,
          });
          this.modal.show = false;
          this.$refs.grid.refresh();
        })
        .catch((response) => {
          const body = JSON.parse(response[0].error.response);
          this.modal.error.set(body.errors);
        });
    },

    removeEmail(id) {
      this.error.reset();
      this.dm
        .remove("", id)
        .then((response) => {
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: this.$t("user.email.toast.success"),
            color: "success",
            autohide: true,
          });
          this.$refs.grid.refresh();
        })
        .catch((response) => {
          const body = JSON.parse(response[0].error.response);
          this.error.set(body.errors);
        });
    },

    markAdPrimaryEmail(id) {
      const requestParams = {
        id: id,
        primary: true,
      };
      this.error.reset();
      this.dm
        .update("", requestParams)
        .then((response) => {
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: this.$t("user.profile.mail.primary_success"),
            color: "success",
            autohide: true,
          });
          this.error.reset();
          this.$refs.grid.refresh();
        })
        .catch((response) => {
          const body = JSON.parse(response[0].error.response);
          this.error.set(body.errors);
        });
    },

    onRemove(item) {
      this.removeEmail(item.id);
    },

    onMarkAsPrimary(item) {
      this.markAdPrimaryEmail(item.id);
    },
  },
});
</script>
